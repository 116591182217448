<template>
  <div class="contact-data fill-height">

    <v-row class="fill-height">
      <left-side-image/>
      <v-col cols="12" md="7">

        <nav-bar-page/>

        <v-container>

          <div v-if="!user.login">

            <div v-if="adoView">

              <!-- REGISTER-->
              <iframe
                  :src="'https://adocolombia-qa.ado-tech.com/CoopcentralQA/validar-persona?callback=' + baseUrl + '/ado-webhook&key=27247ABA50A1E51&projectName=TCDigital_BCC_QA&product=1'"
                  style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;width:100%;" height="520" width="100%" frameborder="0" title="description"
                  allow="camera">
              </iframe>

            </div>

            <div v-else>
              <v-row v-if="!goHome" justify="center" class="px-4">
                <v-col class="mt-4" cols="12" md="12">
                  <h3 class="text-center mb-0 today-light primary--text line-height-1">Estamos procesando tu información</h3>
                  <br>
                  <v-progress-linear indeterminate></v-progress-linear>
                </v-col>
              </v-row>

            </div>

          </div>

          <v-row v-if="user.login && !goHome" justify="center" class="mt-0">
            <v-col cols="12" class="mt-0 py-0">
              <div class="my-0 pt-0 black&#45;&#45;text">
                <h1 class="text-center today-regular text-title" >¡PREPÁRATE PARA LA SELFIE!</h1>
              </div>
            </v-col>
            <v-col cols="11" md="6" class="mt-0 py-0">
              <div class="my-0 pt-0 primary&#45;&#45;text">
                <h3 class="primary&#45;&#45;text text-center mb-0 today-light line-height-1">Realiza una validación biométrica de tu cara para mayor seguridad en el proceso</h3>
              </div>
            </v-col>

            <v-col cols="12" md="12" lg="12">

              <div class="mt-4 text-center" :class="{ 'flash' : isShotPhoto }">

                <web-cam
                    v-if="!isPhotoTaken"
                    ref="webcam"
                    :device-id="deviceId"
                    :playsinline="false"
                    width="370"
                    height="278"
                    @started="onStarted"
                    @stopped="onStopped"
                    @error="onError"
                    @cameras="onCameras"
                    @camera-change="onCameraChange"
                    style="border-radius:20px; transform: scale(-1, 1)"
                />

                <div v-if="isPhotoTaken" class="text-center col-md-12">
                  <figure class="figure">
                    <img :src="selfieImage"
                         height="278" class="img-responsive"
                         style="transform: scale(-1, 1); width: auto;"/>
                  </figure>
                </div>

                <div v-if="!isPhotoTaken">
                  <div class="text-center mt-4">
                    <v-btn
                        @click="onCapture"
                        color="primary"
                        class="px-12 text-capitalize vardi-button"
                        elevation="0"
                        large>
                      Tomar foto
                    </v-btn>
                  </div>
                </div>

                <div v-if="isPhotoTaken">
                  <div class="text-center mt-4">
                    <v-btn
                        @click="existExpeditionDate"
                        :loading="loading"
                        :disabled="loading"
                        color="primary"
                        class="px-12 text-capitalize vardi-button"
                        elevation="0"
                        large>
                      Continuar
                    </v-btn>
                  </div>
                </div>

                <v-img v-if="frontPreviewImage" :src="frontPreviewImage" height="160" class="mt-2" contain></v-img>

              </div>

            </v-col>

          </v-row>


          <v-row v-if="goHome"  justify="center" class="px-4">
            <v-col class="mt-4" cols="12" md="12">
              <h3 class="text-center mb-0 today-light primary--text line-height-1">No se ha podido finalizar la validacion de tu información</h3>
              <br>

              <v-layout justify-center pt-4 mt-6>
                <v-card-actions>
                  <v-btn
                      :loading="loading"
                      :disabled="loading"
                      color="primary"
                      type="submit"
                      class="align-center px-12 text-capitalize vardi-button"
                      @click="$router.push({ path: '/' })">
                    Finalizar
                  </v-btn>
                </v-card-actions>
              </v-layout>

            </v-col>
          </v-row>



        </v-container>
      </v-col>
    </v-row>


    <v-dialog
        v-model="dialogInitialExpeditionDate"
        max-width="400"
        overlay-color="primary"
    >
      <v-card class="pa-4 text-center">

        <v-img max-height="50"
               class="my-4"
               contain
               src="https://app-biocredit.s3.amazonaws.com/web_app/coopcentral/date.png"
        ></v-img>

        <v-card-title class="justify-center text-uppercase font-weight-bold today-medium">
          Fecha de expedición
        </v-card-title>

        <h3 class="text-justify primary--text today-light line-height-1 px-12">Ingresa la fecha de expedición de tu documento de identidad.</h3>


        <div class="px-12 pb-6 mt-4">
          <v-form
              ref="form"
              lazy-validation
              @submit.prevent="goForm">

            <v-text-field
                v-model="expeditionDate"
                :rules="[v => !!v || 'Documento invalido']"
                type="date"
                label="Fecha de expedición CC"
                required
                outlined
                dense
                color="primary"
                class="input-field"
            ></v-text-field>

            <v-layout justify-center pt-4>
              <v-card-actions>
                <v-btn
                    :loading="loading"
                    :disabled="loading"
                    color="primary"
                    type="submit"
                    class="px-12 text-capitalize vardi-button">
                  Continuar
                </v-btn>
              </v-card-actions>
            </v-layout>

          </v-form>
        </div>

      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialogExpeditionDate"
        max-width="400"
        overlay-color="primary"
        persistent
    >
      <v-card class="pa-4 text-center">

        <v-img max-height="50"
               class="my-4"
               contain
               src="https://app-biocredit.s3.amazonaws.com/web_app/coopcentral/date.png"
        ></v-img>

        <v-card-title class="justify-center text-uppercase font-weight-bold today-medium">
          Fecha de expedición
        </v-card-title>

        <h3 class="text-justify primary--text today-light line-height-1 px-12">Ingresa la fecha de expedición de tu documento de identidad.</h3>


        <div class="px-12 pb-6 mt-4">
          <v-form
              ref="form"
              lazy-validation
              @submit.prevent="updateExpeditionDate">

            <v-text-field
                v-model="expeditionDate"
                :rules="[v => !!v || 'Documento invalido']"
                type="date"
                label="Fecha de expedición CC"
                required
                outlined
                dense
                color="primary"
                class="input-field"
            ></v-text-field>

            <v-layout justify-center pt-4>
              <v-card-actions>
                <v-btn
                    :loading="loading"
                    :disabled="loading"
                    color="primary"
                    type="submit"
                    class="px-12 text-capitalize vardi-button">
                  Continuar
                </v-btn>
              </v-card-actions>
            </v-layout>

          </v-form>
        </div>

      </v-card>
    </v-dialog>

    <!-- dialogRestrictions -->
    <v-dialog
        v-model="dialogRestrictions"
        max-width="400"
        overlay-color="primary"
        persistent
    >
      <v-card class="pa-4 text-center">

        <v-img max-height="50"
               class="my-4"
               contain
               :src="dataDialogRestrictions.modal.image"
        ></v-img>

        <v-card-title class="justify-center text-uppercase font-weight-bold today-medium">
          {{ dataDialogRestrictions.modal.title }}
        </v-card-title>

        <h3 class="text-justify primary--text today-light line-height-1 px-12">{{ dataDialogRestrictions.modal.content }}</h3>


        <div class="px-12 pb-6 mt-4">
          <v-form
              ref="form"
              lazy-validation
              @submit.prevent="logout()">

            <v-layout justify-center pt-4>
              <v-card-actions>
                <v-btn
                    :loading="loading"
                    :disabled="loading"
                    color="primary"
                    type="submit"
                    class="px-12 text-capitalize vardi-button">
                  Finalizar
                </v-btn>
              </v-card-actions>
            </v-layout>

          </v-form>
        </div>

      </v-card>
    </v-dialog>

    <!-- Modal de validacion de usuario registerCellphoneDialog -->
    <v-dialog
        v-model="registerCellphoneDialog"
        max-width="400"
        overlay-color="primary"
        persistent
    >
      <v-card class="pa-4 text-center">

        <v-card-title class="white" v-if="codeSent">
          <v-btn
              class="primary--text font-weight-regular pl-0 text-capitalize vardi-button"
              plain
              @click="editCellphone">
            <v-icon style="font-size: 1.8em;">mdi-chevron-left</v-icon>
            Atrás
          </v-btn>
        </v-card-title>

        <v-img max-height="70"
               class="my-4"
               contain
               src="https://app-biocredit.s3.amazonaws.com/web_app/coopcentral/phone+copy.png"
        ></v-img>

        <h2 class="my-3 today-medium justify-center black--text">REGISTRA TU CELULAR</h2>

        <h3 class="text-justify primary--text today-light line-height-1 px-10">
          Te enviaremos vía SMS un código de verificación al número de celular que registres
        </h3>

        <div class="px-10 mt-6" v-if="!codeSent">
          <v-form
              ref="form"
              lazy-validation>

            <v-text-field
                v-model="cellphone"
                :rules="[rules.required, rules.counterMax, rules.counterMin]"
                type="phone"
                label="Celular"
                maxlength="10"
                outlined
                dense
                color="primary"
                class="input-field cellphone-input"></v-text-field>


            <p v-if="messageErrorRegisterCellphone != ''"
               class="today-medium text-center"
               style="color: red !important;">{{ messageErrorRegisterCellphone }}</p>

            <v-layout justify-center>

              <v-card-actions>
                <v-btn
                    v-if="!codeSent"
                    :loading="loading"
                    :disabled="loading"
                    color="primary"
                    class="px-10 mt-0 text-capitalize today-bold vardi-button"
                    @click="initializeEvidente"
                    elevation="0"
                >
                  Continuar
                </v-btn>
              </v-card-actions>
            </v-layout>

          </v-form>
        </div>

        <div class="px-4 mt-6" v-if="codeSent">
          <v-form
              ref="form"
              lazy-validation>
            <v-row>
              <v-col v-for="(key, i) in activationKeyFields" cols="2" class="px-2 text-center">
                <v-text-field
                    type="number"
                    :key="i"
                    :data-length="key.length"
                    :data-index="i"
                    :ref="`input-${i}`"
                    v-model="key.value"
                    v-on:keyup="handleActivationInput($event)"
                    v-on:keydown="handleActivationInputDown($event)"
                    v-on:click="handleClikInput($event)"
                    class="px-0 input-field code-input"
                    outlined
                    dense
                    color="primary"
                    maxLength="1"
                    onwheel="return false;"></v-text-field>
              </v-col>

            </v-row>

            <p v-if="messageErrorOtp != ''"
               class="today-medium text-center"
               style="color: red !important;">{{ messageErrorOtp }}</p>

            <p v-if="!reSendCode" class="primary--text today-medium text-center" style="font-size: 0.7em;">Se habilitará un nuevo código en 00:{{ countdown }}</p>

            <p class="primary--text text-center" v-if="reSendCode">
              <a href="#" @click="sendCellphoneTwilio()" class="text-center today-regular">Reenviar código</a>
            </p>

          </v-form>
        </div>

        <v-card-actions class="justify-center">

          <v-btn
              v-if="codeSent"
              :loading="loading"
              :disabled="loading"
              color="primary"
              class="px-10 mt-0 text-capitalize today-bold vardi-button"
              @click="sendValidateCode"
              elevation="0"
          >
            Enviar
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

<!--    <p class="primary&#45;&#45;text text-center">
      <a href="#" @click="testCountdown()" class="text-center today-regular">Prueba</a>
    </p>-->

    <!-- Modal de validacion por medio de twilio codeForTwilio -->
    <v-dialog
        v-model="codeForTwilio"
        max-width="400"
        overlay-color="primary"
        persistent
    >

      <v-card class="pa-4 text-center">

        <v-card-title class="white" v-if="codeSent">
          <v-btn
              class="primary--text font-weight-regular pl-0 text-capitalize vardi-button"
              plain
              @click="editCellphone">
            <v-icon style="font-size: 1.8em;">mdi-chevron-left</v-icon>
            Atrás
          </v-btn>
        </v-card-title>

        <v-img max-height="70"
               class="my-4"
               contain
               src="https://app-biocredit.s3.amazonaws.com/web_app/coopcentral/phone+copy.png"
        ></v-img>

        <h2 class="my-3 today-medium justify-center black--text">VALIDAR PERFIL</h2>

        <h3 class="text-justify primary--text today-light line-height-1 px-10">
          Te hemos enviado un código de verificación al celular {{ cellphone }}.
        </h3>

        <div class="px-4 mt-6">
          <v-form
              ref="form"
              lazy-validation>
            <v-row>
              <v-col v-for="(key, i) in activationKeyFields" cols="2" class="px-2 text-center">
                <v-text-field
                    type="number"
                    :key="i"
                    :data-length="key.length"
                    :data-index="i"
                    :ref="`input-t-${i}`"
                    v-model="key.value"
                    v-on:keyup="handleActivationInputTwilio($event)"
                    v-on:keydown="handleActivationInputDown($event)"
                    v-on:click="handleClikInput($event)"
                    class="px-0 input-field code-input"
                    outlined
                    dense
                    color="primary"
                    maxLength="1"
                    onwheel="return false;"></v-text-field>
              </v-col>

            </v-row>

            <p v-if="messageErrorOtp != ''"
               class="today-medium text-center"
               style="color: red !important;">{{ messageErrorOtp }}</p>

            <p v-if="!reSendCode" class="primary--text today-medium text-center" style="font-size: 0.7em;">Se habilitará un nuevo código en 00:{{ countdown }}</p>

            <p class="primary--text text-center" v-if="reSendCode">
              <a href="#" @click="sendCellphoneTwilio()" class="text-center today-regular">Reenviar código</a>
            </p>

          </v-form>
        </div>

        <v-card-actions class="justify-center">

          <v-btn
              v-if="codeSent"
              :loading="loading"
              :disabled="loading"
              color="primary"
              class="px-10 mt-0 text-capitalize today-bold vardi-button"
              @click="sendValidateCodeTwilio()"
              elevation="0"
          >
            Enviar
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <!-- dialogErrorOtp -->
    <v-dialog
        v-model="dialogErrorOtp"
        max-width="400"
        overlay-color="primary"
        persistent
    >
      <v-card class="pa-4 text-center">

        <v-card-title class="justify-center text-uppercase font-weight-bold today-medium">
          Lo sentimos
        </v-card-title>

        <h3 class="text-justify primary--text today-light line-height-1 px-12 pt-4">{{ messageError }}</h3>

        <div class="px-12 pb-6 mt-4">
          <v-form
              ref="form"
              lazy-validation
              @submit.prevent="initializeEvidente()">

            <v-layout justify-center pt-4>
              <v-card-actions>
                <v-btn
                    :loading="loading"
                    :disabled="loading"
                    color="primary"
                    type="submit"
                    class="px-12 text-capitalize vardi-button">
                  Aceptar
                </v-btn>
              </v-card-actions>
            </v-layout>

          </v-form>
        </div>

      </v-card>
    </v-dialog>


    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        bottom
        right>
      <div v-html="snackbarMessage"></div>
    </v-snackbar>

  </div>
</template>

<script>
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import NavBarPage from "@/components/Layouts/NavBarPage";
import {locals} from "@/helpers/locals";
import {api} from "@/helpers/axios";
import {constants, services} from "@/helpers/constants";

import { WebCam } from "vue-web-cam";

export default {
  name: 'TakeSelfie',
  components: {LeftSideImage, NavBarPage, WebCam},
  data() {
    return {
      baseUrl: constants.baseUrl,
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: '#',
      user: null,
      loading: false,
      selfieImage: null,
      snackbar: false,
      snackbarMessage: '',
      timeout: 5000,
      frontPreviewImage: null,
      selectedFileFront: null,
      dialogErrorOtp: false,
      messageError: 'Código OTP invalido, intentalo de nuevo',
      dialogInitialExpeditionDate: false,  // Dialogo de inicializacion de fecha de expedicion
      dialogExpeditionDate: false,  // Dialogo de actualizacion de fecha de expedicion
      expeditionDate: '',
      dialogRestrictions: false,    // Dialogo de restricciones
      dataDialogRestrictions: {
        modal: {
          image: '',
          title:'',
          content:'',
          buttons:
          {
          }
        }
      },
      dataDialogDefault: {
        modal: {
          image: 'https://app-biocredit.s3.amazonaws.com/images/coopcentral/pagina-web.png',
          title:'¡LO SENTIMOS!',
          content:'El usuario no cumple con las políticas de la entidad. Comunícate con un asesor.',
          buttons:
              {
                text: 'Finalizar',
                action: 'logout'
              }
        }
      },
      dialogValidateUser: false,    // Dialogo de validacion de usuario (celular)
      registerCellphoneDialog: false,
      messageErrorRegisterCellphone: '',
      loadingCellphone: false,
      cellphone: '',
      codeSent: false,
      activationKeyFields: [
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' }
      ],
      reSendCode: false,
      rules: {
        required: value => !!value || 'Ingresa la información',
        counterMax: value => value.length <= 10 || 'Cantidad máxima 10 digitos',
        counterMin: value => value.length == 10 || 'Cantidad minima 10 digitos'
      },
      dataValidateEvidente: null,
      dataIinitializeOtpServiceEvidente: null,
      dataGenerateOtpCodeServiceEvidente: null,
      dataVerifyOtpServiceEvidente: null,
      codeForTwilio: false,
      expires_in: 60,
      countdown: '',
      timestamp: '',
      validateExpeditionDate: false,
      img: null,
      camera: null,
      deviceId: null,
      devices: [],
      messageErrorOtp: '',
      interval: null,
      adoView: true,
      goHome: false,
    }
  },
  mounted() {

    /*window.Echo.channel('ado-coopcentral')
      .listen('AdoCoopcentral', (event) => {
        console.log(event)
        if(event.data.status) {
          this.adoView = false
          this.registerUserAdo(event)
        }

      })*/

    /*window.Echo.channel('ado-coopcentral-verification')
      .listen('AdoVerification', (event) => {
        console.log('AdoVerification: ', event)
        if(event.data.status) {
          this.adoView = false
          this.registerUserAdo(event)
        }

      })*/

    // ADO
    navigator.mediaDevices.getUserMedia({ video: true })
        .then(function(stream) {
          var video = document.querySelector('video');
          video.srcObject = stream;
          video.play();
        })
        .catch(function(err) {
          console.log('Error: ' + err);
        });

    //this.codeForTwilio = true;
    //this.codeSent = true;

    this.user = locals.getLocal('user');
    console.log(this.user);
    this.validateExpeditionDate = !!this.user.expedition_date;

    if(!this.user.login && this.adoView) {

      setTimeout(function () {
        this.validateFinishAdo()
      }.bind(this), 30000)

    } else {
      clearInterval(this.interval);
    }

    if(this.isCameraOpen) {
      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.stopCameraStream();
    } else {
      this.isCameraOpen = true;
      this.createCameraElement();
    }

  },
  watch: {
    camera: function(id) {
      this.deviceId = id;
    },
    devices: function() {
      // Once we have a list select the first one
      const [first, ...tail] = this.devices;
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    }
  },
  methods: {

    runAdoValidation() {
      this.loading = true
      this.adoView = true
      this.goHome = false

      setTimeout(function () {
        api.get(constants.endPoints.adoValidation, true)
            .then(response => {

              console.log('adoValidation: ', response);

              if(response.data.ado_validation == true) {
                console.log(response.data)
                this.restrictionsValidation();
              } else {

                this.loading = false
                this.adoView = false
                this.goHome = true
                console.log(response.data)
              }
            });
      }.bind(this), 500)
    },

    validateFinishAdo() {

      this.interval = setInterval(() => {

        try {
          if(this.dataForm != null) {
            clearInterval(this.interval);
            this.getDataCurrentForm();
          }

          api.post(constants.endPoints.adoFinish,
              {
                document_id: this.user.document_id
              }
            ).then(response => {

              console.log('adoFinish', response)
              this.loading = false;
              if(response.data.status) {
                clearInterval(this.interval);

                try {
                  this.user.access_token = response.data.token;
                  this.user.token_type = response.data.token_type;
                  this.user.login = false
                  this.adoView = false
                  locals.setLocal('user', JSON.stringify(this.user));

                  this.restrictionsValidation();
                } catch (e) {
                  console.log('Exception: ', e)
                }

              }

            }).catch(function (error) {

              let errors = error.response.data.errors;
              console.log('errors: ', error);
              let message = '';
              for (var [key, value] of Object.entries(errors)) {
                message = message + value + '<br>';
              }
              this.snackbarMessage = message;
              this.snackbar = true;
              this.loading = false;

            }.bind(this))

        } catch (e) {
          console.log(e);
        }

      }, 3000);


    },

    registerUserAdo(response) {
      console.log('Websocket Ado: ', response);

      try {
        this.user.access_token = response.data.token;
        this.user.token_type = response.data.token_type;
        this.user.login = false
        if(response.data.login == 1) {
          this.user.login = true;
        }
        locals.setLocal('user', JSON.stringify(this.user));

        this.restrictionsValidation();
      } catch (e) {
        console.log('Exception: ', e)
      }

    },
    handleActivationInputDown: function (event) {
      event.target.select();
    },

    handleClikInput(event) {
      event.target.select();
    },
    /*testCountdown() {   // metodo apra pruebas
      // Se inicia la cuenta atras
      this.startCountDown();
      this.codeSent = true;

      this.codeForTwilio = true;
    },*/
    logout() {
      services.logout();
      this.$router.push('/');
    },
    editCellphone() {
      this.codeSent = false;
      this.codeForTwilio = false;
      this.registerCellphoneDialog = true;
      this.messageErrorOtp = '';

      clearInterval(this.interval);

      this.activationKeyFields = [
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' }
      ];

    },
    existExpeditionDate() {
      if(this.validateExpeditionDate || this.user.login) {
        this.goForm();
      } else {
        this.dialogInitialExpeditionDate = false;
      }
    },
    goForm: function () {
      this.loading = true;
      this.user = locals.getLocal('user');

      // Convertir base64 a png
      var arr = this.selfieImage.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      var selfieFile = new File([u8arr], 'selfie.png', {
        type: mime
      });
      // Fin de convertir el base64

      console.log('selfieFile:: ', selfieFile, this.user);
      this.user.expedition_date = this.expeditionDate;

      if (this.user.login) {

        var formData = new FormData();
        formData.append('document_id', this.user.document_id);
        formData.append('document_type', this.user.document_type);
        formData.append('face_model', selfieFile);
        formData.append('expedition_date',  this.user.expedition_date);

        api.postFile(constants.endPoints.login, formData, true).then(function (response) {

          this.loading = false;
          this.dialogInitialExpeditionDate = false;

          if (response.data.errors != null) {
            let errors = response.data.errors;
            console.log('errors: ', errors);
            let message = '';
            for (var [key, value] of Object.entries(errors)) {
              console.log(key + ' : ' + value);
              message = message + value + '<br>';
            }
            this.snackbarMessage = message;
            this.snackbar = true;

            this.takePhoto();
          } else {
            console.log('Sin errores', response);

            this.user.access_token = response.data.data.token;
            this.user.token_type = response.data.data.token_type;
            this.user.login = true;
            locals.setLocal('user', JSON.stringify(this.user));

            //this.$router.push('/formulario')

            this.runAdoValidation()

          }
        }.bind(this)).catch(function (error) {
          let errors = error.response.data.errors;
          console.log('errors: ', error);
          let message = '';
          try {
            for (var [key, value] of Object.entries(errors)) {
              console.log(key + ' : ' + value);
              if(value == 'ADO-ERROR') {
                this.loading = false
                this.adoView = false
                this.goHome = true
                message = 'Ha ocurrido un error de validacion'
              } else {
                message = message + value + '<br>';
              }


            }
          } catch (e) {
            console.log(e);
            message = e;
          }

          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;
          this.dialogInitialExpeditionDate = false;

          this.takePhoto();

        }.bind(this));

      } else {

        var formData = new FormData();
        formData.append('document_id', this.user.document_id);
        formData.append('document_type', this.user.document_type);
        formData.append('coupon', this.user.coupon);
        formData.append('validate_document_id', this.user.validate_document_id);
        formData.append('face_model', selfieFile);
        formData.append('expedition_date', this.user.expedition_date);

        api.postFile(constants.endPoints.registerUser, formData, true).then(function (response) {
          console.log('registerUser: ', response);

          this.loading = false;
          this.dialogInitialExpeditionDate = false;

          if (response.data.errors != null) {
            let errors = response.data.errors;
            console.log('errors: ', errors);
            let message = '';
            for (var [key, value] of Object.entries(errors)) {
              console.log(key + ' : ' + value);
              message = message + value + '<br>';
            }
            this.snackbarMessage = message;
            this.snackbar = true;
            this.user.login = false;
            locals.setLocal('user', JSON.stringify(this.user));

            this.takePhoto();
          } else {
            console.log('Sin errores', response);

            this.user.access_token = response.data.data.token;
            this.user.token_type = response.data.data.token_type;
            this.user.login = true;
            locals.setLocal('user', JSON.stringify(this.user));

            //this.$router.push('/formulario')

            this.runAdoValidation()

          }

        }.bind(this)).catch(function (error) {
          let errors = error.response.data.errors;
          console.log('errors: ', error);
          let message = '';
          for (var [key, value] of Object.entries(errors)) {
            message = message + value + '<br>';
          }
          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;
          this.dialogInitialExpeditionDate = false;

          this.takePhoto();
        }.bind(this))

      }

    },

    restrictionsValidation() {

      this.loading = true;

      api.post(constants.endPoints.restrictionsValidation, {}, true)
        .then(response => {

          this.loading = false;

          this.dataDialogRestrictions = response.data.data;

          if(this.dataDialogRestrictions.restriction_state) {       // Cliente con restricciones 'si'
            this.dialogRestrictions = true;
          } else if(!this.dataDialogRestrictions.evidente_state) {  // Cliente validado 'no'
            this.registerCellphoneDialog = true;
          } else {
            //this.registerCellphoneDialog = true; // PRUEBAS
            this.$router.push('/formulario');
          }

          // PRUEBAS
          //this.registerCellphoneDialog = true;

        }).catch(function (error) {

          let errors = error.response.data.errors;
          console.log('errors: ', error);
          let message = '';
          for (var [key, value] of Object.entries(errors)) {
            message = message + value + '<br>';
          }
          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;

      }.bind(this))

    },

    updateExpeditionDate() {
      this.loading = true;
      api.post(constants.endPoints.updateExpeditionDate, {
        expedition_date: this.expeditionDate
      }, true)
          .then(response => {

            this.loading = false;

            this.expeditionDate = '';
            this.dialogExpeditionDate = false;
            this.initializeEvidente();

          }).catch(function (error) {

            let errors = error.response.data.errors;
            console.log('errors: ', error);
            let message = '';
            for (var [key, value] of Object.entries(errors)) {
              message = message + value + '<br>';
            }
            this.snackbarMessage = message;
            this.snackbar = true;
            this.loading = false;
            this.dialogExpeditionDate = false;

          }.bind(this))
    },

    // --- SERVICVIOS EVIDENTE --- //
    initializeEvidente() {
      this.messageErrorRegisterCellphone = '';
      this.validateServiceEvidente();
    },

    validateServiceEvidente() {

      this.loading = true;

      api.get(constants.endPoints.validateServiceEvidente, true)
        .then(response => {
          this.loading = false;
          this.dialogErrorOtp = false;
          this.dataValidateEvidente = response.data.data;

          this.user.reg_validation = this.dataValidateEvidente.regValidacion;
          locals.setLocal('user', JSON.stringify(this.user));

          if(this.dataValidateEvidente.resultadoProceso === 'true') { // 'Indica si se pudo realizar o no el proceso de validación'
            if(this.dataValidateEvidente.valFechaExp === 'true') {   // 'Indica si la validación de la fecha de expedición para cédula de ciudadanía fue exitosa o no'

              switch (this.dataValidateEvidente.resultado) {  // Si el proceso fue exitoso indica el código resultado de la validación.

                case '01':
                case '05':

                  if(this.dataValidateEvidente.alertas == 'true') {
                    /* respuestaAlerta
                     00 Error general, comunicarse con el área encargada (servicio al cliente), error en los
                     parámetros de la parametria (alerta y cuestionario) sobre la base de datos.

                     01 Código que indica se debe generar el siguiente flujo (cuestionario o iniciar transacción
                     OTP) y él ciudadano presenta alertas

                     02 Código que indica no se debe generar el siguiente flujo (cuestionario o iniciar
                     transacción OTP) y él ciudadano presenta alertas

                     03 Código que indica que el ciudadano no presenta alertas y se debe continuar con el
                     siguiente flujo (cuestionario o iniciar transacción OTP)

                     04 Código que indica que el sistema de alertas está inactivo y se debe continuar con el
                     siguiente flujo (cuestionario o iniciar transacción OTP)
                    */
                    switch (this.dataValidateEvidente.respuestaAlerta) {
                      case '00':
                      case '02':
                        this.dataDialogRestrictions = this.dataDialogDefault;
                        this.dialogRestrictions = true;
                        break;
                      case '01':
                      case '03':
                      case '04':
                        this.initializeOtpServiceEvidente();
                        break;

                    }
                  } else {
                    this.initializeOtpServiceEvidente();
                  }

                  break;

                case '06':

                  this.dataDialogRestrictions = this.dataDialogDefault;
                  this.dataDialogRestrictions.modal.content = 'Se sugiere no continuar el proceso debido a que la validación de identidad no fue exitosa, los nombres y/o apellidos digitados no concuerdan con el número de identificación digitado';
                  this.dialogRestrictions = true;

                  break;
                case '07':

                  this.dataDialogRestrictions = this.dataDialogDefault;
                  this.dataDialogRestrictions.modal.content = 'Se sugiere no continuar el proceso debido a que la validación de identidad no fue exitosa, debido a que el número de identificación digitado no se encuentra relacionado en la información del buró';
                  this.dialogRestrictions = true;

                  break;
                case '08':

                  this.dataDialogRestrictions = this.dataDialogDefault;
                  this.dataDialogRestrictions.modal.content = 'Se sugiere no continuar el proceso debido a que la validación de identidad no fue exitosa, debido a que el número de identificación digitado presenta un estado diferente a vigente en la información del buró.';
                  this.dialogRestrictions = true;

                  break;
                case '09':

                  this.dataDialogRestrictions = this.dataDialogDefault;
                  this.dataDialogRestrictions.modal.content = 'Se sugiere no continuar el proceso debido a que la validación de identidad no fue exitosa, y por parametria la entidad ha relacionado el tope máximo s diario a realizar validaciones fallidas.';
                  this.dialogRestrictions = true;

                  break;
                case '11':

                  this.dataDialogRestrictions = this.dataDialogDefault;
                  this.dataDialogRestrictions.modal.content = 'Se sugiere no continuar el proceso debido a que la validación de identidad no fue exitosa';
                  this.dialogRestrictions = true;

                  break;
                default:
                  console.log('entra a default', this.dataValidateEvidente, this.dataValidateEvidente.resultado);
                  break;

              }

            } else {
              // SE DEBE ACTUALIZAR LA FECHA DE EXPEDICION
              //this.dialogExpeditionDate = true;

              this.goHome = true;
              this.registerCellphoneDialog = false;
              this.dialogRestrictions = false;
            }
          } else {
            this.dataDialogRestrictions = this.dataDialogDefault;
            this.dialogRestrictions = true;
          }

          /*if(this.dataValidateEvidente.valFechaExp === 'false') {
            this.timestamp = this.dataValidateEvidente.FechaExpedicion.timestamp;
            this.reValidateServiceEvidante();
          } else {

          }*/

        }).catch(function (error) {
          console.log('Error: ', error)
          let message = '';

          let errors = error.response.data;

          if(errors.code == 400) {
            message = errors.message
            this.loading = false
            this.goHome = true
            this.adoView = false
            this.registerCellphoneDialog = false
          } else {
            for (var [key, value] of Object.entries(errors)) {
              message = message + value + '<br>';
            }
          }
          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;
          this.dialogErrorOtp = false;

        }.bind(this));

    },

    initializeOtpServiceEvidente() {

      this.loading = true;

      this.user.cellphone = this.cellphone;
      locals.setLocal('user', JSON.stringify(this.user));

      api.post(constants.endPoints.initializeOtpServiceEvidente, {
        reg_validation: this.dataValidateEvidente.regValidacion
      }, true)
        .then(response => {

          this.loading = false;

          this.dataIinitializeOtpServiceEvidente = response.data.data;

          /*
            Flag booleano para confirmar si el proceso fue ejecutado satisfactoriamente
            true -> Ejecutar método GENERAR CODIGO OTP
            false -> Ejecutar método PREGUNTAS
          */
          if(this.dataIinitializeOtpServiceEvidente.ResultadoGeneracion.resultadoOTP) { // otp
            this.generateOtpCodeServiceEvidente();
          } else {    // preguntas
            this.questionsServiceEvidente();
          }


        }).catch(function (error) {

          let errors = error.response.data.errors;
          console.log('errors: ', error);
          let message = '';
          for (var [key, value] of Object.entries(errors)) {
            message = message + value + '<br>';
          }
          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;

      }.bind(this))

    },

    generateOtpCodeServiceEvidente() {

      this.loading = true;

      api.post(constants.endPoints.generateOtpCodeServiceEvidente, {
        reg_validation: this.dataValidateEvidente.regValidacion,
        cellphone: this.cellphone,
        uuid_transaction: this.dataIinitializeOtpServiceEvidente.ResultadoGeneracion.idTransaccionOTP
      }, true)
          .then(response => {

            this.loading = false;

            this.dataGenerateOtpCodeServiceEvidente = response.data.data;

            /*
            ResultadoGeneracion/codResultadoOTP
            3 OTP NO APROBADO POR RECONOCER - FALSE - Ejecutar método PREGUNTAS
            4 OTP SIN RESPUESTA - TRUE - Ejecutar método Verificar OTP.
            7 TRANSACCION INVALIDADA POR INTENTO ADICIONAL - FALSE - Ejecutar método Validar
            99 ERROR GENERANDO CODIGO OTP - FALSE - Ejecutar método PREGUNTAS
            */

            switch (this.dataGenerateOtpCodeServiceEvidente.ResultadoGeneracion.codResultadoOTP) {
              case '3':
              case '99':
                this.sendCellphoneTwilio();
                //this.questionsServiceEvidente();
                break;
              case '4': // aparece el modal del codigo OTP
                this.startCountDown();
                this.registerCellphoneDialog = true;
                this.codeSent = true;
                break;
              case '7':     // MOSTRAR ERROR Y DE INTENTOS POR DIA Y HACER LOGOUT | CESAR
                this.validateServiceEvidente();
                break;
            }

          }).catch(function (error) {

            let errors = error.response.data.errors;
            console.log('errors: ', error);
            let message = '';
            for (var [key, value] of Object.entries(errors)) {
              message = message + value + '<br>';
              if(key == 'cellphone') {
                this.registerCellphoneDialog = true;
                this.messageErrorRegisterCellphone = value.shift();
              }
            }
            this.snackbarMessage = message;
            this.snackbar = true;
            this.loading = false;

          }.bind(this))

    },

    questionsServiceEvidente() {

      this.$router.push('/validar-perfil');

    },

    sendCellphoneTwilio: function () {

      this.loading = true;
      this.messageErrorRegisterCellphone = '';
      this.messageErrorOtp = '';

      api.get(constants.endPoints.phoneIsRegistered, true)
        .then(response => {

          //this.policies = response.data.data;

          if(response.data.status == true) {
            this.loading = false;
            this.registerCellphoneDialog = false;
            this.questionsServiceEvidente();
          } else {
            this.registerCellphoneDialog = true;

            api.post(constants.endPoints.registerPhone, {
              cellphone: this.cellphone
            }, true)
              .then((response) => {
                this.loading = false;

                //this.request.id = response.data.data.id;

                // Se inicia la cuenta atras
                clearInterval(this.interval);
                this.startCountDown();
                this.codeSent = true;

                this.codeForTwilio = true;
                this.registerCellphoneDialog = false;

              }, (error) => {
                console.log('post', error, error.message, error.code);
                this.loading = false;

                if(error.code == 422) {
                  this.questionsServiceEvidente();
                }
              }).catch(function (error) {

                let errors = error.response.data.errors;
                console.log('errors: ', error);
                let message = '';
                for (var [key, value] of Object.entries(errors)) {
                  message = message + value + '<br>';
                  if(key == 'cellphone') {
                    this.registerCellphoneDialog = true;
                    this.messageErrorRegisterCellphone = value.shift();
                  }
                }
                this.snackbarMessage = message;
                this.snackbar = true;
                this.loading = false;

              }.bind(this));

          }
        });

    },
    sendValidateCode: function () {
      this.loading = true;
      this.messageErrorOtp = '';

      let token = '';

      this.activationKeyFields.forEach((value) => {
        token = token + value.value;
      });

      if(token.length == 6) {
        api.post(constants.endPoints.verifyOtpServiceEvidente, {
          reg_validation: this.dataValidateEvidente.regValidacion,
          otp: token,
          uuid_transaction: this.dataIinitializeOtpServiceEvidente.ResultadoGeneracion.idTransaccionOTP,
          cellphone: this.cellphone
        }, true)
            .then((response) => {
              this.dataVerifyOtpServiceEvidente = response.data.data;
              let dataResponse = response.data;
              this.loading = false;

              if(dataResponse.status) {

                switch (this.dataVerifyOtpServiceEvidente.VerificarCodigoOTPRespuesta.resultadoValidacion) {

                  case '1':   // Finalizar Flujo (Aprobado)
                    this.$router.push('/formulario')
                    break;
                  case '2':   // Finalizar Flujo (No Aprobado)

                      // SE QUITO EL LOGOUT - CESAR - 19122022
                    //locals.clearLocal();
                    //this.$router.push('/');

                    //this.dialogErrorOtp = true;
                    //this.messageError = 'Código OTP invalido, intentalo de nuevo';

                    this.messageErrorOtp = 'Código OTP invalido, intentalo de nuevo';

                    this.activationKeyFields = [
                      { length: 1, value: '' },
                      { length: 1, value: '' },
                      { length: 1, value: '' },
                      { length: 1, value: '' },
                      { length: 1, value: '' },
                      { length: 1, value: '' }
                    ];

                    this.snackbarMessage = 'Código OTP invalido, intentalo de nuevo';
                    this.snackbar = true;
                    this.sendCellphoneTwilio();

                    break;
                  case '6':   // Ejecutar método PREGUNTAS

                    this.messageErrorOtp = 'Se ha excedido el tiempo máximo permitido para el envío del código OTP.';

                    this.activationKeyFields = [
                      { length: 1, value: '' },
                      { length: 1, value: '' },
                      { length: 1, value: '' },
                      { length: 1, value: '' },
                      { length: 1, value: '' },
                      { length: 1, value: '' }
                    ];

                    this.sendCellphoneTwilio();
                    break;
                  case '8':   // Ejecutar método VALIDAR Reiniciar Flujo
                    this.validateServiceEvidente();

                    this.activationKeyFields = [
                      { length: 1, value: '' },
                      { length: 1, value: '' },
                      { length: 1, value: '' },
                      { length: 1, value: '' },
                      { length: 1, value: '' },
                      { length: 1, value: '' }
                    ];

                    /*this.codeForTwilio = true;

                    this.registerCellphoneDialog = false;
                    this.codeSent = false;
                    this.reSendCode = true;

                    this.activationKeyFields = [
                      { length: 1, value: '' },
                      { length: 1, value: '' },
                      { length: 1, value: '' },
                      { length: 1, value: '' },
                      { length: 1, value: '' },
                      { length: 1, value: '' }
                    ];*/

                    break;

                }
              } else {
                this.snackbarMessage = dataResponse.message;
                this.snackbar = true;
              }

            }, (error) => {
              console.log('post', error);
            }).catch(function (error) {

              let errors = error.response.data.errors;
              console.log('errors: ', error);
              let message = '';
              for (var [key, value] of Object.entries(errors)) {
                message = message + value + '<br>';
                if(key == 'cellphone') {
                  this.registerCellphoneDialog = true;
                  this.messageErrorRegisterCellphone = value.shift();
                }
              }
              this.snackbarMessage = message;
              this.snackbar = true;
              this.loading = false;

            }.bind(this))

      } else {
        this.loading = false;
        this.snackbarMessage = 'El token debe tener 6 digitos';
        this.snackbar = true;
      }

    },

    sendValidateCodeTwilio: function () {
      this.loading = true;
      this.messageErrorOtp = '';

      let token = '';

      this.activationKeyFields.forEach((value) => {
        token = token + value.value;
      });

      if(token.length == 6) {
        api.post(constants.endPoints.validatePhone, {
          cellphone: this.cellphone,
          token: token
        }, true)
            .then((response) => {
              let dataResponse = response.data;
              this.loading = false;

              this.activationKeyFields = [
                { length: 1, value: '' },
                { length: 1, value: '' },
                { length: 1, value: '' },
                { length: 1, value: '' },
                { length: 1, value: '' },
                { length: 1, value: '' }
              ];

              if(dataResponse.status) {
                this.questionsServiceEvidente();
              } else {
                this.messageErrorOtp = dataResponse.message;
                this.snackbarMessage = dataResponse.message;
                this.snackbar = true;
              }

            }, (error) => {
              console.log('post', error);
            });
      } else {
        this.loading = false;
        this.snackbarMessage = 'El token debe tener 6 digitos';
        this.snackbar = true;
      }


    },
    handleActivationInput: function (event) {

      event.target.select();
      // Grab input's value
      let value = event.target.value;
      // Grab data-index value
      let index = parseInt(event.target.dataset.index);
      // Grab data-length value
      let maxlength = event.target.dataset.length;

      console.log(this.activationKeyFields, this.activationKeyFields[index].value.length, value, index, maxlength, this.$refs);

      // Shift focus to next input field if max length reached
      if (value.length >= maxlength) {
        console.log('salta 2');
        if (typeof this.activationKeyFields[index + 1] == 'undefined') {
          console.log('undefined salta 2');
          event.preventDefault();
          return;
        }

        try {
          this.$refs[`input-${parseInt(index + 1)}`][0].focus();
        } catch (e) {
          console.log('exception: ', e);
        }

        event.preventDefault();
      }

      if (event.key == 'Backspace') {
        if (typeof this.activationKeyFields[index - 1] == 'undefined') {
          console.log('Backspace salta 2');
          event.preventDefault();
          return;
        }

        try {
          this.$refs[`input-${parseInt(index - 1)}`][0].focus();
        } catch (e) {
          console.log('exception: ', e);
        }

        event.preventDefault();
      }

      console.log('handleActivationInput: ', event, value, this.activationKeyFields);

    },
    handleActivationInputTwilio(event) {
      // Grab input's value
      let value = event.target.value;
      // Grab data-index value
      let index = parseInt(event.target.dataset.index);
      // Grab data-length value
      let maxlength = event.target.dataset.length;

      console.log(this.activationKeyFields, this.activationKeyFields[index].value.length, value, index, maxlength, this.$refs);


      // Shift focus to next input field if max length reached
      if (value.length >= maxlength) {
        console.log('salta 2');
        if (typeof this.activationKeyFields[index + 1] == 'undefined') {
          console.log('undefined salta 2');
          event.preventDefault();
          return;
        }

        try {
          this.$refs[`input-t-${parseInt(index + 1)}`][0].focus();
        } catch (e) {
          console.log('exception: ', e);
        }

        event.preventDefault();
      }

      if (event.key == 'Backspace') {
        if (typeof this.activationKeyFields[index - 1] == 'undefined') {
          console.log('Backspace salta 2');
          event.preventDefault();
          return;
        }

        try {
          this.$refs[`input-t-${parseInt(index - 1)}`][0].focus();
        } catch (e) {
          console.log('exception: ', e);
        }

        event.preventDefault();
      }

      console.log('handleActivationInput: ', event, value, this.activationKeyFields);

    },

    startCountDown: function() {
      this.countdown = this.expires_in;
      this.interval = setInterval(() => {
        if (this.countdown === 0) {
          clearInterval(this.interval);
          this.countdown = this.expires_in;
          this.reSendCode = true;
          //this.reSendSignatureOtp();
        } else {
          this.countdown -= 1;
          this.reSendCode = false;
        }
      }, 1000);
    },

    goNext(event){
      this.$refs.digit2.focus()
    },


    // ---- CAMERA COMPONENT ------ ////

    onCapture() {
      if(this.$refs.webcam) {
        this.selfieImage = this.$refs.webcam.capture();
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      setTimeout(() => {
        if(this.isPhotoTaken) {
          this.onStop();
        } else {
          this.onStart();
        }
      }, 100);

    },
    onStarted(stream) {
      console.log("On Started Event", stream);
    },
    onStopped(stream) {
      console.log("On Stopped Event", stream);
    },
    onStop() {
      this.$refs.webcam.stop();
    },
    onStart() {
      this.$refs.webcam.start();
    },
    onError(error) {
      console.log("On Error Event", error);
    },
    onCameras(cameras) {
      this.devices = cameras;
      console.log("On Cameras Event", cameras);
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
    },

    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true
      });

      /*navigator.mediaDevices
          .getUserMedia(constraints)
          .then(stream => {
            this.isLoading = false;
            this.$refs.camera.srcObject = stream;
            this.$refs.camera.play();
          })
          .catch(error => {
            this.isLoading = false;
            alert("May the browser didn't support or there is some errors. " + error);
          });*/


    },
    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();
      tracks.forEach(track => {
        track.stop();
      });
    },
    takePhoto() {

      if(this.$refs.webcam) {
        this.selfieImage = this.$refs.webcam.capture();
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      setTimeout(() => {
        if(this.isPhotoTaken) {
          this.onStop();
        } else {
          this.onStart();
        }
      }, 100);

    },

    downloadImage() {
      const download = document.getElementById("downloadPhoto");
      const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg", 1.0)
          .replace("image/jpeg", "image/octet-stream");
      download.setAttribute("href", canvas);
    }
  },
  beforeDestroy() {
    this.stopCameraStream();

  }
}
</script>

<style lang="scss">

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  appearance: none;
  margin: 0;
}


.v-input__slot {
  padding: 0 10px !important;
}

</style>
